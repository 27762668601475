import React, { PureComponent } from "react";
import { getImage } from "../service/app.servic";

class BookSingle extends PureComponent {
  
  render() {
    const { book } = this.props
    console.log(book);
    
    return (
      <React.Fragment>
        
        <div>
          <div className="full-book">
            <h4 class="mb10">{book.title}</h4>
            <p>signature: {book.signature}</p>
            <p>medianumber: {book.medianumber}</p>
            <img src={getImage(book.firstPage.pageId)} alt="" className="i"/>
            
          </div>
        </div>
        
      </React.Fragment >
    );
  }
}

export default BookSingle;
