import React from "react";

const Header = () => (
        <div className="jumbotron">
          <div className="container text-center">
            <img src="banner.png" alt="" className="img" height="150px" />
            <h1>Elektronischer Lesesaal</h1>
            <p>Dokumente durchsuchen</p>
          </div>
        </div>
);

export default Header;
