import React from "react";

const BookList = (props) => {
  const { sessionId, books } = props

  return <div class="space-medium">
    <div class="container">
      <div class="row">
        {books.map((elt, key) => {
          return <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 row pointer" key={key}
            onClick={() => props.itemClick(elt)}>
            <div class="video-testimonial-block">
              <div class="video-thumbnail">
                <img src={`${elt.firstPage.thumbnailUrl}&sessionId=${sessionId}`} alt="" class="img-fluid" />
              </div>
            </div>
            <div class="video-testimonial-content">
              <h5 class="mb10">{elt.title}</h5>
              <p>signature: {elt.signature}</p>
              <p>medianumber: {elt.medianumber}</p>
            </div>
          </div>
        })}
      </div>
    </div>
  </div>
}
export default BookList;
