import React from 'react';
import { 
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect
 } from "react-router-dom";
import './App.css';
import Header from './components/header';
import { isLogged as testisLogged } from './service/app.servic';
import Search from './pages/search/search';
import Login from './pages/login/login';

const isLogged = testisLogged();

class App extends React.Component {

  render() {
    return (
      <Router>
        <Header  />
          <Switch>
            <AuthRoute path="/login" component={Login} exact />
            <PrivateRoute path="/" component={Search} exact />
          </Switch>
      </Router>
    );
  }
}

//Routes used when user is logged in
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    isLogged ? <Component {...props} /> : <Redirect to='/login' />
  )} />
)
// Routes used for authentification
const AuthRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    !isLogged ? <Component {...props} /> : <Redirect to='/' />
  )} />
)

export default App;
