import $ from "jquery";

export const base_url = process.env.REACT_APP_BASE_URL;

const sesId = localStorage.getItem('sessionId');

export const getThumb= (pageId) => `${base_url}/mob/repository?method=getThumbnail&pageId=${pageId}&sessionId=${sesId}`;
export const getImage= (pageId) => `${base_url}/mob/repository?method=getImage&pageId=${pageId}&sessionId=${sesId}`;


const API = function (url) {
  return new Promise((resol, rej) => {
    let res = {};
    window.jsonpCallbackFunction = function (data) {
      res = data;
    };

    $.getScript(base_url + url)
      .done(function (script, textStatus) {
        resol(res);
      })
      .fail(function (jqxhr, settings, exception) {
        rej(exception);
      });
  });
}

export const login = (login, password) => {
  const url = `/mob/rest?service=session&method=login&login=${login}&password=${password}`;
  return API(url);
}

export const isLogged = () => {
  return sesId !== null;
};


export const searchServ = {
  searchOne: (input_1) => {
    const url = `/mob/rest?service=search&method=searchDocuments&sessionId=${sesId}&query=${input_1}`;
    return API(url);
  },
  searchTwo: (resultId) => {
    const url = `/mob/rest?service=search&method=getResultList&sessionId=${sesId}&resultId=${resultId}`;
    return API(url);
  }
}
