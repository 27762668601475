import React from "react";
import $ from 'jquery';
import './login.css';
import { login } from "../../service/app.servic";

class Login extends React.PureComponent {


  submit = (e) => {
    e.preventDefault();
    login(this.login.value.trim(), this.pwd.value.trim()).then(data => {
      if (data.login && data.login.length){
        localStorage.setItem('sessionId', data.login[0].sessionId);
        window.location.href = '/'
      }
    }).catch(err => {
      console.log(err);
    })
  }

  
  
  render() {

    return  <div class="wrapper fadeInDown" id="login">
    <div id="formContent">
      <form onSubmit={this.submit}>
        <input type="text" id="login" class="fadeIn second" name="login" placeholder="login"
         defaultValue="anonymous" ref={x => this.login = x} />
        <input type="password" id="password" class="fadeIn third" name="login" placeholder="password" 
        defaultValue="anonymous"  ref={x => this.pwd = x}/>
        <input type="submit" class="fadeIn fourth" value="Log In" />
      </form>
    </div>
  </div>

  }
}


export default Login;
