import React from "react";
import './search.css';
import { searchServ } from "../../service/app.servic";
import BookList from "../../components/book.list";
import BookSingle from "../../components/book.single";

class Search extends React.PureComponent {

  state = {
    nbrResult: 0,
    results: [],
    searching: false,
    book: {}
  }

  UNSAFE_componentWillMount() {
    const sessionId = localStorage.getItem('sessionId');
    this.setState({ sessionId })
  }

  // componentWillMount(){
  //   const book = '{"title":"Zawiera numera 1 - 616, lata 984 - 1287","signature":"0213805","medianumber":"0213805-1","pageCount":651,"restservicePermissionRead":"allow","date":"1877","extradata":{"issue_no":""},"firstPage":{"pageId":"01334042f57636d6ea84081923b7eeba26738f2","pageNr":1,"thumbnailUrl":"http://digi96.martin-opitz-bibliothek.de/mob/repository?method=getThumbnail&pageId=01334042f57636d6ea84081923b7eeba26738f2","imageUrl":"http://digi96.martin-opitz-bibliothek.de/mob/repository?method=getImage&pageId=01334042f57636d6ea84081923b7eeba26738f2"},"abstract":"...prolabuntur ad culpam. Unde speramus, quod ipse qui solus laborem et do- <b>lorem</b>... "}'
  //   console.log(JSON.parse(book));
  //   this.setState({book: JSON.parse(book), zoomBook: true});
  // }

  searchOne = () => {
    this.setState({ searching: true });
    searchServ.searchOne(this.key.value.trim()).then(res => {
      if (res.resultInfo && res.resultInfo.length) {

        searchServ.searchTwo(res.resultInfo[0].resultId).then(data => {
          if (data.resultList && data.resultList.length) {
            console.log(data.resultList);
            this.setState({ results: data.resultList[0].resultEntries });
          }
        }).finally(() => this.setState({ searching: false, error: null }));
      }
    }).catch(err => this.setState({ searching: false, error: "Error while searching, please retry!" }));
  }

  onSelectItem = book => {
    console.log(JSON.stringify(book));
    this.setState({ book, zoomBook: true });
  }

  render() {

    const { sessionId, zoomBook, book } = this.state

    return <React.Fragment>
      <div className="line-truc"><span className="home-link pointer" 
      onClick={() => this.setState({zoomBook: false})}>Home</span> {zoomBook && <span> <span>/</span> Book </span>}</div>
      {!zoomBook && <section className="search-sec foo" id="search">
        <div className="container">
          <form action="#" method="post" novalidate="novalidate">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-12 p-0">
                    <input type="text" id="tilte" className="form-control search-slt"
                      placeholder="Enter keyword" ref={x => this.key = x} />
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12 p-0">
                    <select className="form-control search-slt" id="exampleFormControlSelect1">
                      <option>Select Vehicle</option>
                      <option>Example one</option>
                    </select>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12 p-0">
                    <button type="button" id="search_1" className="btn btn-success wrn-btn"
                      onClick={this.searchOne}>Search</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" id="search_result">
              {this.state.results.length > 0 && <h1>Nombre de resultats: {this.state.results.length} </h1>}

              <BookList books={this.state.results} sessionId={sessionId}
                itemClick={this.onSelectItem} />

            </div>
          </form>
        </div>

      </section>}
      {zoomBook && <BookSingle book={book} />}

    </React.Fragment>
  }
}


export default Search;
